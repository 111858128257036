import React, { useEffect, useState } from "react";
import Web3  from "web3";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import bnb_img from "../../assets/img/bnb.svg";
import usdt_img from "../../assets/img/usdt.svg";
import abtc_img from "../../assets/img/abtc.svg";
import Whitepaper from "../../assets/whitepaper.pdf";
import CountDownOne from "../CountDown/CountDownOne";
import { Link } from "react-router-dom";
import { init, useConnectWallet } from "@web3-onboard/react";
import preSaleData from "./PreSale.json";
import usdtData from "./UsdtContract.json"
import { ethers } from "ethers";
import axios from 'axios';



const Banner = (props) => {
  const [selectedCurrency, setSelectedCurrency] = useState("BNB");
  const [inputValue, setInputValue] = useState("");
  const [outputValue, setOutputValue] = useState("");
  const [priceOfBNB,setpriceOfBNB] = useState("");

  
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  let ethersProvider;
  let signer;
  let userAddress;

  const PRICE_PER_TOKEN = 0.01;

  const handleChangeCurrency = (e) => {
    setSelectedCurrency(e.target.value);
  };

    useEffect(() => {
      const fetchPrice = async () => {
        const url = `https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd`;
  
        try {
          const response = await axios.get(url);
          console.log('response',response);
          if (response.data && response.data.binancecoin && response.data.binancecoin.usd) {
            setpriceOfBNB(response.data.binancecoin.usd);
            console.log("priceOfBNB",priceOfBNB)
          } else {
            console.error('Unable to fetch price data');
          }
        } catch (error) {
          console.error('Error fetching price:', error);
        }
      };
  
      fetchPrice();
    }, []);


  const handleChangeInput = (e) => {
    setInputValue(e.target.value);
    if(selectedCurrency === "USDT"){
      setOutputValue(e.target.value / PRICE_PER_TOKEN);
    }
    if(selectedCurrency === "BNB"){
      setOutputValue(e.target.value * (priceOfBNB))
    }
  };


  const buyToken= async()=>{
    try {
      if(!wallet){
        connect()
      }
      ethersProvider = new ethers.providers.Web3Provider(wallet.provider, 'any')
      const signer = ethersProvider.getSigner();
      const abi = preSaleData.abi;
      const address = preSaleData.address;
      const usdtABI = usdtData.abi;
      const usdtAddress = usdtData.address;
      const preSaleInstance = new ethers.Contract(
        address,
        abi,
        signer
      );
      const amountInWei = ethers.utils.parseEther(
        inputValue.toString()
      );
      if(selectedCurrency === "USDT"){
        const usdtInstance = new ethers.Contract(
          usdtAddress,
          usdtABI,
          signer
        )
        userAddress = wallet.accounts[0];
        let USERADDRESS = userAddress.address;  
        await usdtInstance.approve(address,amountInWei);
        await preSaleInstance.buyTokenUSDT(amountInWei,USERADDRESS)
      }
      if(selectedCurrency === "BNB"){
        await preSaleInstance.buyTokenBNB(amountInWei);
      }

    } catch (error) {
      console.log("error connecting wallet",error.message)
    }
  }

  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-6">
            <div className="banner-content text-center text-lg-start">
              <h2 className="title">
                Join Future of Algorithmic <span>Crypto</span> Trading Strategies
              </h2>
            </div>
            <div className="d-flex align-items-center justify-content-center justify-content-lg-start mb-md-5 mb-3">
              {/* Icon section */}
            </div>
            <div className="banner-progress-wrap ms-0">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                <Link to="#" className="btn btn-two me-2">
                  Buy ABTC
                </Link>
                <Link target="_blank" to={Whitepaper} className="btn ms-2">
                  Whitepaper
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="buy_card">
              <div className="banner-countdown-wrap">
                <h5 className="text-center">OUR ICO WILL STARTS IN!</h5>
                <CountDownOne />
              </div>
              <div className="price_tb">
                <span></span> 1 ABTC = ${PRICE_PER_TOKEN} <span></span>
              </div>
              <div className="d-flex align-items-center justify-content-center c_pair">
                <button
                  className={`btn ${selectedCurrency === "BNB" ? "active" : ""}`}
                  value="BNB"
                  onClick={handleChangeCurrency}
                >
                  <img src={bnb_img} className="img-fluid" alt="" /> BNB
                </button>
                <button
                  className={`btn ${selectedCurrency === "USDT" ? "active" : ""}`}
                  value="USDT"
                  onClick={handleChangeCurrency}
                >
                  <img src={usdt_img} className="img-fluid" alt="" /> USDT
                </button>
              </div>
              <div className="c_form">
                <div className="row gx-3">
                  <div className="form-group mb-3 col-lg-12">
                    <label>Enter Quantity</label>
                    <div className="c_input">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        value={inputValue}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-lg-12">
                    <label>ABTC You Receive</label>
                    <div className="c_input">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="0"
                        value={outputValue}
                        readOnly // Prevent user input, as it's calculated
                      />
                    </div>
                  </div>
                </div>
                <button className="btn btn-block btn-two w-100" onClick={buyToken} disabled={!inputValue}> BUY ABTC</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
