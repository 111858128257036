import React from "react";
import PageLoader from "../components/PageLoader/PageLoader";
import BlogBreadcrumb from "../components/Breadcrumb/Breadcrumb";
import FooterOne from "../components/Footer/FooterOne";
import HeaderOne from "../components/Header/HeaderOne";

const LayoutPageBanner = (props) => {
  return (
    <div>
      <PageLoader />
      <HeaderOne />
      <main>
        <BlogBreadcrumb title={props.pageTitle} item={props.item} />
        <section className="blog-area pt-130 pb-130">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                {props.children}
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterOne />
    </div>
  );
};

export default LayoutPageBanner;
