import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import logoImage from "../../../src/assets/img/logo/fullLogo.svg";
import $ from "jquery";
import { handleClickScroll } from "../../lib/helpers";
import { onboard } from "../../lib/WalletCoonect";
import { init, useConnectWallet } from "@web3-onboard/react";
import { ethers } from 'ethers'



const HeaderOne = () => {


  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();

  // const connectWallet = async () => {
  //   const wallets = await onboard.connectWallet()
  //   setWalletData(wallets[0]);
  // }


  // console.log(walletData, 'rrrrrrrrrrr');

  // sticky nav bar
  const [stickyClass, setStickyClass] = useState({
    fixed: "",
    header: "",
  });

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight < 245
        ? setStickyClass({ fixed: "", header: "" })
        : setStickyClass({ fixed: "active-height", header: "sticky-menu" });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  // mobile menu
  useEffect(() => {
    // //SubMenu Dropdown Toggle
    if ($(".menu-area li.menu-item-has-children ul").length) {
      $(".menu-area .navigation li.menu-item-has-children").append(
        '<div className="dropdown-btn"><span className="fas fa-angle-down"></span></div>'
      );
    }

    //Mobile Nav Hide Show
    if ($(".mobile-menu").length) {
      let mobileMenuContent = $(".menu-area .main-menu").html();
      $(".mobile-menu .menu-box .menu-outer").append(mobileMenuContent);

      //Dropdown Button
      $(".mobile-menu li.menu-item-has-children .dropdown-btn").on(
        "click",
        function () {
          $(this).toggleClass("open");
          $(this).prev("ul").slideToggle(500);
        }
      );
      //Menu Toggle Btn
      $(".mobile-nav-toggler_tab").on("click", function () {
        $("body").addClass("mobile-menu-visible");
      });

      //Menu Toggle Btn
      $(".menu-backdrop, .mobile-menu .close-btn").on("click", function () {
        $("body").removeClass("mobile-menu-visible");
      });
    }
  }, []);

  // active link switching
  const { hash, pathname } = useLocation();

  const isActiveLink = (id) => {
    if (pathname === '/buytoken') {
      window.history.replaceState(null, null, '/');
    } /* window.history.replaceState(null, null, '/'); */
    return id == hash ? "active" : "";
  };


  return (
    <header id="header">
      <div id="header-fixed-height" className={cn(stickyClass.fixed)} />
      <div id="sticky-header" className={cn("menu-area", stickyClass.header)}>
        <div className={cn("container custom-container")}>
          <div className="row">
            <div className="col-12">
              <div className={"mobile-nav-toggler mobile-nav-toggler_tab"}>
                <i className="fas fa-bars" />
              </div>
              <div className={"mobile-nav-toggler connect_wallet_btn"}>
                {/* <a href="javascript:void(0)" className="cw_btn text-white" onClick={() => connectWallet()}>
                  {walletData?.accounts?.[0]?.address ? 'Connected' : 'Connect Wallet'}
                </a> */}
              </div>
              <div className="menu-wrap">
                <nav className={"menu-nav"}>
                  <div className="logo">
                    <Link to={"/"}>
                      <img src={logoImage} alt="AFRICANBTC Logo" />
                    </Link>
                  </div>

                  <div className={cn("navbar-wrap main-menu d-none d-lg-flex")}>
                    <ul className={"navigation"}>
                      <li className={cn(hash == "#home" && "active")}>
                        <a href="#home" className={"section-link"} onClick={() => handleClickScroll("home")}>
                          Home
                        </a>
                      </li>
                      {/* <li className={cn(hash === "#about" && "active")}>
                        <a
                          href={'#about'}
                          className={"section-link"}
                          onClick={() => handleClickScroll("about")}
                        >
                          About us
                        </a>
                      </li> */}
                      <li className={isActiveLink("#howtobuy")}>
                        <a
                          href={'#howtobuy'}
                          className={"section-link"}
                          onClick={() => handleClickScroll("howtobuy")}
                        >
                          How to Buy
                        </a>
                      </li>
                      <li className={isActiveLink("#sales")}>
                        <a
                          href="#sales"
                          className={"section-link"}
                          onClick={() => handleClickScroll("sales")}
                        >
                          Token
                        </a>
                      </li>
                      <li className={isActiveLink("#roadmap")}>
                        <a
                          href="#roadmap"
                          className={"section-link"}
                          onClick={() => handleClickScroll("roadmap")}
                        >
                          Roadmap
                        </a>
                      </li>
                      <li className={isActiveLink("#whitepaper")}>
                        <a
                          href="#whitepaper"
                          className={"section-link"}
                          onClick={() => handleClickScroll("whitepaper")}
                        >
                          Whitepaper
                        </a>
                      </li>
                      <li className={isActiveLink("#contact")}>
                        <a
                          href="#contact"
                          className={"section-link"}
                          onClick={() => handleClickScroll("contact")}
                        >
                          Contact us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className={cn("header-action", "")}>
                    <ul>
                      <li className={"header-lang"}>
                      <button class="btn btn_gradient" disabled={connecting} onClick={() => (wallet ? disconnect(wallet) : connect())}>
                    {connecting ? 'Connecting' : wallet ? `Disconnect ` : 'Connect Wallet'}
                </button>
                        {/* <a href="javascript:void(0)" className="" onClick={() => connectWallet()}>{walletData?.accounts?.[0]?.address ? 'Connected' : 'Connect Wallet'}</a> */}
                        {/* <a href="javascript:void(0)" className="">Connect Wallet</a> */}

                      </li>
                      {/* <li className={"header-btn"}> */}
                        {/* <a href="javascript:void(0)" className="btn" onClick={() => connectWallet()}>{walletData?.accounts?.[0]?.address ? 'Buy ABTC' : 'Buy ABTC'}</a> */}
                      {/* </li> */}
                    </ul>
                  </div>
                </nav>
              </div>

              {/* <!-- Mobile Menu  --> */}
              <div className={"mobile-menu"}>
                <nav className={"menu-box"}>
                  <div className={"close-btn"}>
                    <i className="fas fa-times"></i>
                  </div>
                  <div className={"nav-logo"}>
                    <Link to="/">
                      <img src={logoImage} alt="AFRICANBTC Logo" title="" />
                    </Link>
                  </div>

                  <div className={"menu-outer"}>
                    {/* <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->  */}
                  </div>

                  <div className={"social-links"}>
                    <ul className="clearfix">
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>

              <div className={"menu-backdrop"} />
              {/* <!-- End Mobile Menu --> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
