import React from "react";

import CoustamRouting from "./CoustamRouting";
import ComingSoon from "./components/ComingSoon/About";
import { init, useConnectWallet,Web3OnboardProvider } from '@web3-onboard/react'
import injectedModule, { ProviderLabel }  from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'


const injected = injectedModule({
  filter: {
    [ProviderLabel.Detected]: ['Android', 'desktop','macOS','iOS','Android Browser']
  }
})
// will be needing client account for this
const wcV2InitOptions = {
  projectId: 'c66e0c6936802bf343a96671d67b2de6',
  requiredChains: [
    56,
    97
  ],
}

const walletConnect = walletConnectModule(wcV2InitOptions)

const web3Onboard = init({
    wallets: [
      injected,
      walletConnect
    ],
    theme: 'dark',
    connect: {
      autoConnectLastWallet: false
    },
    chains: [
      {
        id: '0x38',
        token: 'BNB',
        label: 'Binance Smart Chain',
        rpcUrl: 'https://koge-rpc-bsc.48.club'
      },
      {
        id: '0x61',
        token: 'BNB Testnet',
        label: 'Binance Smart Chain Testnet',
        rpcUrl: 'https://bsc-testnet-rpc.publicnode.com'
      },

    ],
  
  })





function App() {
  return (
    <Web3OnboardProvider web3Onboard={web3Onboard}>

     <CoustamRouting />
    // <ComingSoon />
    </Web3OnboardProvider>

  );
}

export default App;
