import React from "react";
import shape01 from "../../assets/img/banner/banner_shape01.png";
import shape02 from "../../assets/img/banner/banner_shape02.png";
import shape03 from "../../assets/img/banner/banner_shape03.png";
import fireIcon from "../../assets/img/logo/logo.svg";
import CountDownOne from "../CountDown/CountDownOne";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <section className="banner-area banner-bg coming_soon_sec" >
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      <div className="container" >
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <img src={fireIcon} alt="" />
              <h1>COMING SOON</h1>
              <h2 className="title mb-2">
               <span> WE ARE ALOMST THERE </span> 
              </h2>
              <p>
               Stay tuned for somthing amazing!!! 
              </p>
            </div>  
          </div>
        </div>
        {/* <div className="row justify-content-center">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">ICO Will Start In..</h2>

              <CountDownOne />
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default ComingSoon;
