import React from "react";
import { Link } from "react-router-dom";
import w3 from "../../../src/assets/img/images/w3.png"; 
import meta from "../../../src/assets/img/images/meta.png";
import trust from "../../../src/assets/img/images/trust.png";
import secure from "../../../src/assets/img/images/secure.png";
import wallet from "../../../src/assets/img/images/wallet.png";

const HowtoBuy = (props) => {
  return (
    <section id="howtobuy" className="team-area pt-70 pb-70">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-70"> <span className="sub-title">HOW TO BUY</span>
              <h2 className="title">
                How to buy <span>AFRICANBTC</span>
              </h2> </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div class="team-content about-content wow fadeInRight">
              <div class="step step-1 section-title mb-30">
                <h2 class="title d-inline-flex  mb-4">Step 1: &nbsp; <span>Setup</span></h2>
                <p class="description d-flex "> <img src={w3} class="explain-icon" /> <span> You will need to have a web3 compatible wallet such as Metamask connected to your browser, alternatively you can also use other wallets supported by Wallet Connect, such as Trust Wallet</span> </p>
                <p class="description  d-flex"> <img src={meta} class="explain-icon" /> <span>  To ensure a smooth process for participating in the presale, we suggest using a desktop browser  and Metamask. You can add Metamask to your browser by <a href="https://metamask.io/download/" target="_blank">clicking here</a></span> </p>
                <p class="description  d-flex"> <img src={trust} class="explain-icon" /> <span> For mobile devices we suggest using Trust Wallet for the smoothest user experience.</span> </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div class="team-content about-content wow fadeInRight">
              <div class="step step-1 section-title mb-30">
                <h2 class="title d-inline-flex mb-4">Step 2:   &nbsp; <span> Connect </span></h2>
                <p class="description d-flex "> <img src={secure} class="explain-icon" /> <span> Enter the amount you wish to purchase and click "Connect Wallet" to choose your preferred payment option for acquiring $TUK tokens. Ensure your wallet has sufficient balance in the desired currency to display available options.</span> </p>
                <p class="description  d-flex"> <img src={wallet} class="explain-icon" /> <span>  After selecting your desired currency in our widget, you'll be redirected to your wallet for completing the transaction. Please make sure you have enough balance to cover gas fees.</span> </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowtoBuy;
