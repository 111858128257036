import React from "react";
import { useHorizontalScroll } from "../../lib/hooks/useHorizontalScroll";
import RoadmapItem from "./RoadmapItem";

const Roadmap = () => {
  const roadmap_items = [
    {
      roadmapTitle: "Mid of Q2 2024",
      title: "2024",
      info: [
        "-Market Research & Idea Development",
      ],
    },
    {
      roadmapTitle: "Quarter 3 2024",
      title: "2024",
      info: [
        "Launch of ICO Website for AfricanBTC Coin",
         "Listing of AfricanBTC Coin on Pancakeswap",
         "Listing of AfricanBTC Coin on some popular CEX",
      ],
    },

    {
      roadmapTitle: "Quarter 4 2024",
      title: "2024",
      info: [
        "Launch of AfricanBTC Web-Exchange",
        "Mobile Apps for AfricanBTC Exchange",
        "Staking Module",
      ],
    },

    {
      roadmapTitle: "Quarter 1 2025",
      title: "2025",
      info: [
        "Launch of AfricanBTC Blockchain",
        "Migration of AfricanBTC Coin to",
        "AfricanBTC Blockchain",
      ],
    },
    {
      roadmapTitle: "Mid of Q2 2025",
      title: "2024",
      info: [
        "DEFI Wallet launch for AfricanBTC Blockchain",
        "iOS/Android Apps",
      ],
    },
     
  ];

  const scrollRef = useHorizontalScroll();

  return (
    <section id="roadmap" className="roadmap-area pt-130 pb-130">
      <div className="container custom-container-two">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-8">
            <div className="section-title text-center mb-60">
              <span className="sub-title">OUr Roadmap</span>
              <h2 className="title">
              <span>AFRICANBTC</span> Strategy  <br /> and  Project  Plan
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div
              className="bt-roadmap_x bt-roadmap-scrollbar"
              ref={scrollRef}
              style={{ overflow: "auto" }}
            >
              <div className="bt-roadmap-wrap">
                {roadmap_items.map((x, index) => (
                  <RoadmapItem key={index} item={x} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Roadmap;
