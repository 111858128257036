import React, { useState } from "react";
import LayoutPageBanner from "../../layouts/LayoutPageBanner";
const BuyToken = () => {

  let address = []
  const [amount, setamount] = useState();
  const [token, settoken] = useState();

  let web3js = []
  const AdminAccountAddress = "0x3a0753B73ac7954c3fDEad57669C3D37a87C2B6f";


  const handleInput = async (e) => {
    switch (e.target.name) {
      case 'amount':
        settoken(e.target.value / 0.000050)
        setamount(e.target.value)
        break;
      case 'token':
        settoken(e.target.value)
        setamount(e.target.value * 0.000050)
        break;
      default:
        break;
    }
  }


  const BuyToken = async () => {
    if (!address) {
      alert('Please Connect wallet')
    } else if (!amount) {
      alert('Please Enter Amount to buy..')
    } else {
      const gasPrice = await web3js?.eth?.getGasPrice();
      try {
        const hash = await web3js?.eth?.sendTransaction({
          from: address,
          to: AdminAccountAddress,
          value: web3js?.utils?.toWei(amount, 'ether'),
          gas: 21000,
          gasPrice
        }, function (err, transactionHash) {
          if (transactionHash) {
            alert('Successfull...')
          }
        }
        )
      } catch (e) {
        // console.log("Connected Wallet=>", address)
      }
    }
  };


  return (
    <LayoutPageBanner pageTitle={"Buy ABTC"} item={"Buy ABTC"} >
      <div className="buy_token" >
        <div className="contact-form-wrap">
          <form action="#">
            <div className="row justify-content-center m-auto">
              <div className="col-md-12">
                <div className="form-grp">
                  <label className="label"> BNB </label>
                  <input type="text" placeholder="Enter Amount" name="amount" required value={amount} onChange={(e) => { handleInput(e) }} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-grp mb-3">
                  <label className="label"> AFRICANBTC </label>
                  <input type="text" placeholder="Enter Token" required  name="token"  value={token} onChange={(e) => { handleInput(e) }} />
                </div>
                <div class="balance-libra card-success mb-5"><div class="token-img-bg_right" id="lc-data"> Price:- <b>$ 0.121</b> </div></div>
              </div>
              <div className="submit-btn text-center col-md-12">
                <button type="button" className="btn btn-two w-100" onClick={() => BuyToken()}>
                  Buy ABTC
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </LayoutPageBanner>
  );
};

export default BuyToken;
